html {
  height: 100%;
}

body {
  font-family: 'Istok Web', sans-serif;
  font-size: 16px;
  /* min-height: 100%; */
  display: grid;
  /* grid-template-rows: 1fr auto; */
}

.ui.menu .item>img:not(.ui) {
  width: 80px;
}
.ui.inverted.blue.menu {
  height: 60px;
}

.location-subheader {
  color: #333;
}

.login-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 80%;
  margin: 15vh auto;
}

.login-content {
  position: relative;
  top: 5vw;
}

.login-content p {
  width: 80%;
}

.login-signup {
  position: relative;
  top: 5vw;
}

.login-form {
  padding-bottom: 10px;
}

.avatar {
  position: relative;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(30%, -50%);
          transform: translate(30%, -50%);
  color: white;
}

h3.section-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 25px;
}

h2.login-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 30px;
}

.button {
  /* padding-top: 10px!important; */
  margin-bottom: 5px!important;
}

.event-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 5px;
}

.ui.form .field>label {
  font-family: 'Istok Web', sans-serif;
  font-size: 13px;
  font-weight: bold;
}

.ui.button {
  font-family: 'Istok Web', sans-serif;
}

.ui.items>.item>.content>.description {
  font-size: 16px;
  max-width: 75%
}

div.description {
  max-width: 75%
}

.ui.menu {
  font-family: 'Istok Web', sans-serif;
}

.ui.items>.item>.content>.header {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

div.meta {
  font-size: 14px;
}

.ui.items>.item>.content>.header.invitation {
  font-weight: 400;
}

.ui.card:first-child {
  margin-top: 10px;
}

.ui.grid>* {
  padding: 10px;
}

.main.container {
  margin-top: 7em;
}

.main-section {
  margin-top: 100px;
  -webkit-align-content: center;
          align-content: center;
}

.main-content {
  width: 75% !important;
  position: absolute;
  top: 100px;
  left: 50%;
  /* margin-bottom: 100px; */
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.ui.text.container {
  max-width: 960px!important;
  margin-bottom: 5em;
}

div.description {
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

div.header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

i.edit.icon.right.floated {
  margin-right: 0;
}

button.no-bottom-margin {
  margin-bottom: 0!important;
  margin-right: 8px!important;
}

@media only screen and (min-width: 1200px) {
  .ui.grid.container {
    -webkit-align-content: center!important;
            align-content: center!important;
  }
}

.beer-image img {
  width: 300px;
  height: auto;
  margin: 5px 10px;
}

.beer-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.beer-item-name {
  font-family: 'Montserrat', sans-serif;
  font-size: 2em;
}

div.meta.beer-item-style {
  font-size: 1.2em;
}

div.meta.beer-item-abv {
  font-size: 1em;
}

.beer-card-image-container {
  width: 100%;
  padding-top: 100%;
  position: relative;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,.1);
}

.breweries-list {
  margin-top: 50px;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

